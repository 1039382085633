/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';

import useFetch from '../hooks/useFetch';
import { CurrentUser } from '../models';
import { CustomCheckboxInteface, CustomInputInteface } from '../models/inputs';
import { useAlert } from './alert.context';

interface UserInterface {
  user: CurrentUser;
  loginLoading: boolean;
  loginError: any;
  setUser: any;
  login: (
    email: CustomInputInteface,
    password: CustomInputInteface,
    remember: CustomCheckboxInteface,
  ) => void;
  logout: () => void;
  forceUpdate: () => void;
}

const initialValue = {
  user: {
    email: '',
    token: '',
    role: 0,
    _id: '',
  },
  loginLoading: false,
  loginError: '',
  setUser() {},
  login() {},
  logout() {},
  forceUpdate() {},
};

const UserContext = createContext<UserInterface>(initialValue);

export const useUserContext = () => useContext(UserContext);

interface Props {
  children: JSX.Element;
}

const AUTH_TOKEN = 'authenticationToken';

export function UserContextProvider({ children }: Props) {
  const {
    loading: loginLoading,
    doFetch: doLoginFetch,
    error: loginError,
  } = useFetch(`${process.env.REACT_APP_API_URL}/user/login`);
  const { doFetch: doUserFetch, error: userError } = useFetch(
    `${process.env.REACT_APP_API_URL}/user?populate=profile_pic&populate=expertises&populate=member_classes&populate=industries&populate=community_roles`,
  );
  const [authToken, setAuthToken] = useState(() => {
    const localToken = localStorage.getItem(AUTH_TOKEN);
    const sessionToken = sessionStorage.getItem(AUTH_TOKEN);

    if (!localToken && !sessionToken) return '';
    if (localToken) return JSON.parse(localToken);
    if (sessionToken) return JSON.parse(sessionToken);
  });
  const [user, setUser] = useState<CurrentUser>({
    email: '',
    token: authToken,
    role: 0,
    _id: '',
  });

  const { setAlert } = useAlert();

  const [update, forceUpdate] = useReducer((x) => x + 1, 0);

  const setSessionStorageToken = useCallback((token: string) => {
    sessionStorage.setItem(AUTH_TOKEN, JSON.stringify(token));
    setAuthToken(token);
  }, []);

  const setLocalSessionToken = useCallback((token: string) => {
    localStorage.setItem(AUTH_TOKEN, JSON.stringify(token));
    setAuthToken(token);
  }, []);

  const removeAuthToken = useCallback(() => {
    sessionStorage.removeItem(AUTH_TOKEN);
    localStorage.removeItem(AUTH_TOKEN);
    setUser({
      email: '',
      token: '',
      role: 0,
      _id: '',
    });
  }, []);

  useEffect(() => {
    if (!authToken) return;
    (async () => {
      const res = await doUserFetch({
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      if (res) {
        setUser({
          ...res,
          token: authToken,
        });
      } else {
        removeAuthToken();
      }
    })();
  }, [authToken, update]);

  const login = useCallback(
    async (
      email: CustomInputInteface,
      password: CustomInputInteface,
      remember: CustomCheckboxInteface,
    ) => {
      const res = await doLoginFetch({
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: email.value,
          password: password.value,
        }),
      });
      if (res.token) {
        setAlert({
          message: 'Logged in successfully.',
        });
        if (remember.checked) {
          setLocalSessionToken(res.token);
        } else {
          setSessionStorageToken(res.token);
        }
      }
    },
    [],
  );

  const logout = useCallback(async () => {
    if (authToken) {
      removeAuthToken();
      setUser(initialValue.user);
      setAlert({
        message: 'Logged out.',
      });
    }
  }, [authToken]);

  useEffect(() => {
    if (!userError) return;
    logout();
  }, [userError]);

  const value = useMemo(
    () => ({
      user,
      login,
      logout,
      setUser,
      forceUpdate,
      loginLoading,
      loginError,
      userError,
    }),
    [user, loginError, loginLoading],
  );
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}
