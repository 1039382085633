interface Props {
  type: 'submit' | 'button';
  className: string;
  children: React.ReactNode;
  loading?: boolean;
  disabled?: boolean;
  onClick?(): void;
}

function CustomButton({ type, loading, disabled, className, children, onClick }: Props) {
  return (
    <button
      className={className}
      type={type === 'submit' ? 'submit' : 'button'}
      onClick={onClick}
      disabled={loading || disabled}
    >
      {children}
      {loading && <span className="loader">Loading...</span>}
    </button>
  );
}

export default CustomButton;
