import InsightsLister from '../../Components/Insights/InsightsLister/InsightsLister';
import PageTitle from '../../common/PageTitle/PageTitle';

function Insights() {
  return (
    <>
      <div className="wrap wrap--md">
        <PageTitle title="Discover the latest research and theory behind the practice of creative leadership" />
      </div>
      <InsightsLister />
    </>
  );
}

export default Insights;
