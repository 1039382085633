import { createRoot } from 'react-dom/client';

interface Properties {
  map: google.maps.Map;
  position: google.maps.LatLng;
  content: JSX.Element;
  id?: string;
}

class Marker {
  map: google.maps.Map;

  position: google.maps.LatLng;

  content: JSX.Element;

  id?: string;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  overlay: any;

  hot: boolean;

  visible: boolean;

  constructor({ map, position, content, id }: Properties) {
    this.map = map;
    this.position = position;
    this.content = content;
    this.id = id;
    this.hot = false;
    this.visible = true;
    this.overlay = new google.maps.OverlayView();
  }

  init() {
    this.overlay.onAdd = () => {
      if (this.overlay.container || !this.visible) return;
      const container = document.createElement('div');
      const pane = this.overlay.getPanes()?.floatPane;
      if (!pane) return;
      pane.classList.add('marker-pane');
      createRoot(container).render(this.content);
      container.style.position = 'absolute';
      container.classList.add('map-marker-container');
      this.overlay.container = container;
      pane.appendChild(container);
    };
    this.overlay.draw = () => {
      if (!this.overlay.container) return;
      const pos = this.overlay.getProjection().fromLatLngToDivPixel(this.position);
      if (!pos) return;
      this.overlay.container.style.left = `${pos.x}px`;
      this.overlay.container.style.top = `${pos.y}px`;
      if (this.hot) {
        this.overlay.container.classList.add('hot');
      } else {
        this.overlay.container.classList.remove('hot');
      }
      this.overlay.container.addEventListener('click', (e: Event) => {
        e.stopPropagation();
      });
    };
    this.overlay.onRemove = () => {
      if (!this.overlay.container) return;
      this.overlay.container.parentNode?.removeChild(this.overlay.container);
      this.overlay.container = null;
    };

    this.overlay.getPosition = () => this.position;
    this.overlay.getVisible = () => this.visible;
    this.overlay.id = this.id;

    this.overlay.update = (hotMarker: boolean) => {
      if (!this.overlay.container) return;
      this.hot = hotMarker;
      this.overlay.draw();
    };

    return this.overlay;
  }
}

export default Marker;
