import UserEditForm from '../../../Components/User/UserEditForm/UserEditForm';
import PageTitle from '../../../common/PageTitle/PageTitle';

function Edit() {
  return (
    <section className="user-edit">
      <div className="wrap wrap--xs">
        <PageTitle title="Profile page" backLabel="Back to profile" backPath="/profile" />
      </div>
      <div className="wrap wrap--xs">
        <UserEditForm />
      </div>
    </section>
  );
}

export default Edit;
