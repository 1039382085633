import { Outlet } from 'react-router-dom';

import './UserLayout.css';

function UserLayout() {
  return (
    <div className="user-layout">
      <Outlet />
    </div>
  );
}

export default UserLayout;
