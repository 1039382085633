import { useCallback, useEffect, useMemo, useRef } from 'react';
import { Link, useSearchParams } from 'react-router-dom';

import CustomInput from '../../../common/CustomInput/CustomInput';
import CustomSelect from '../../../common/CustomSelect/CustomSelect';
import { Icon } from '../../../common/Icon/Icon';
import Message from '../../../common/Message/Message';
import Pager from '../../../common/Pager/Pager';
import TagsList from '../../../common/TagsList/TagsList';
import useFetch from '../../../core/hooks/useFetch';
import { useFormInput } from '../../../core/hooks/useFormInput';
import { Insights, Tag } from '../../../core/models';
import './InsightsLister.css';

const BASE_URL = `${process.env.REACT_APP_API_URL}`;
const SEARCHABLE_FIELDS = ['title', 'body', 'authors', 'summary'];

function InsightsLister() {
  const [searchParams, setSearchParams] = useSearchParams();
  const category = useMemo(() => searchParams.get('category') || '', [searchParams]);
  const searchValue = useMemo(() => searchParams.get('search') || '', [searchParams]);
  const page = useMemo(() => searchParams.get('page') || '1', [searchParams]);
  const timeout = useRef<ReturnType<typeof setInterval> | null>(null);
  const search = useFormInput(searchValue);
  const { result, doFetch, loading, error } = useFetch(
    `${BASE_URL}/articles/published?populate=tags&populate=img${
      !category ? '' : `&tags=${category}`
    }${
      !searchValue
        ? ''
        : SEARCHABLE_FIELDS.reduce((str, value) => `${str}&${value}=${searchValue}`, '')
    }&per_page=10&page=${page}`,
  );
  const { result: tags, doFetch: doTagsFetch } = useFetch(`${BASE_URL}/tags/published`);

  useEffect(() => {
    doTagsFetch();
  }, []);

  useEffect(() => {
    doFetch();
  }, [category, searchValue, page]);

  useEffect(() => {
    if (timeout.current) clearTimeout(timeout.current);
    searchParams.delete('page');
    timeout.current = setTimeout(() => {
      if (search.value) {
        searchParams.set('search', search.value);
      } else {
        searchParams.delete('search');
      }
      setSearchParams(searchParams);
    }, 500);
    return () => {
      if (timeout.current) clearTimeout(timeout.current);
    };
  }, [search.value]);

  const categoryOptions = useMemo(
    () => (!tags ? [] : tags.items.map((el: Tag) => ({ value: el.name, label: el.name }))),
    [tags],
  );

  const handleCategory = useCallback(
    (e: React.FormEvent<HTMLSelectElement>) => {
      searchParams.delete('page');
      if (e.currentTarget.value) {
        searchParams.set('category', e.currentTarget.value);
      } else {
        searchParams.delete('category');
      }
      setSearchParams(searchParams);
    },
    [searchValue],
  );

  const insightsList = useMemo(
    () =>
      result?.items.map((item: Insights) => (
        <li className="insights-lister__item" key={item._id}>
          <div className="insights-lister__content">
            <Link className="insights-lister__link" to={`/insight/${item.slug}`}>
              <div className="insights-lister__img-container">
                <div className="insights-lister__aspect-container">
                  <img
                    className="insights-lister__img contain-img"
                    src={`${BASE_URL}/uploads/${item.img.uri}`}
                    alt={item.img.alt}
                  />
                </div>
              </div>
              <h2 className="insights-lister__title h3">{item.title}</h2>
              <p className="insights-lister__summary">{item.summary}</p>
              {!item.tags.length ? null : <TagsList tags={item.tags} />}
            </Link>
          </div>
        </li>
      )) || [],
    [result],
  );

  return (
    <section className="insights-lister">
      <div className="wrap wrap--md">
        <div className="insights-lister__header">
          <div className="insights-lister__search">
            <CustomInput
              id="search"
              name="search"
              type="text"
              placeholder="Search"
              input={search}
              icon={<Icon.Search />}
            />
          </div>
          <div className="insights-lister__category">
            <CustomSelect
              id="category"
              name="category"
              placeholder="Category"
              options={categoryOptions}
              value={category}
              onChange={handleCategory}
            />
          </div>
        </div>
        {!error ? null : <Message text={error.message} status="error" />}
        {insightsList.length ? (
          <ul className="insights-lister__list">{insightsList}</ul>
        ) : result ? (
          <h2 className="insights-lister__message h3">No Insights found.</h2>
        ) : null}
        {insightsList.length && result?.pager?.total > 1 ? (
          <Pager total={result.pager.total} current={+page} />
        ) : null}
        {!loading ? null : (
          <div className="page-loader fade-in" style={{ margin: '16px 0' }}>
            <span className="sr-only">Loading</span>
            <div className="loader" />
          </div>
        )}
      </div>
    </section>
  );
}

export default InsightsLister;
