import { useCallback, useRef } from 'react';

import placeholder from '../../../assets/placeholder.png';
import TagsList from '../../../common/TagsList/TagsList';
import { Member, Tag } from '../../../core/models';
import './CommunityItem.css';

interface Props {
  member: Member;
  hotMarker: string | null;
  setHotMarker: React.Dispatch<React.SetStateAction<string | null>>;
  setSelectedMemberId: React.Dispatch<React.SetStateAction<string | null>>;
  openUserModal: (ref: HTMLButtonElement) => void;
}

function CommunityItem({
  member,
  hotMarker,
  setHotMarker,
  setSelectedMemberId,
  openUserModal,
}: Props) {
  const ref = useRef<HTMLButtonElement>(null);
  const setHot = useCallback(() => {
    setHotMarker(member._id);
  }, []);

  const clearHot = useCallback(() => {
    setHotMarker(null);
  }, []);

  const selectMember = useCallback(() => {
    if (!ref.current) return;
    setSelectedMemberId(member._id);
    openUserModal(ref.current);
  }, [ref]);

  return (
    <li className="community-item">
      <button
        className={`community-item__container${hotMarker === member._id ? ' active' : ''}`}
        type="button"
        ref={ref}
        onClick={selectMember}
        onMouseEnter={setHot}
        onMouseLeave={clearHot}
        onFocus={setHot}
        onBlur={clearHot}
      >
        <div className="community-item__info">
          <h2 className="community-item__name body-large">
            {member.firstname} {member.lastname}
          </h2>
          <div className="community-item__exp body-small">
            {member.expertises?.map((el: Tag) => el.name).join(', ')}
          </div>
          {!member.community_roles?.length ? null : <TagsList tags={member.community_roles} />}
        </div>
        <div className="community-item__media" id={`community-member-${member._id}`}>
          {!member.profile_pic ? (
            <img src={placeholder} alt="THNK member" />
          ) : (
            <img
              src={`${process.env.REACT_APP_API_URL}/uploads/${member.profile_pic?.uri}`}
              alt={member.profile_pic?.alt}
            />
          )}
        </div>
      </button>
    </li>
  );
}

export default CommunityItem;
