import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import PageTitle from '../../common/PageTitle/PageTitle';
import useFetch from '../../core/hooks/useFetch';
import PageNotFound from '../PageNotFound/PageNotFound';

const PAGE_URL = `${process.env.REACT_APP_API_URL}/page`;

function Page() {
  const { slug } = useParams();
  const { result: page, doFetch, loading, error } = useFetch(`${PAGE_URL}?slug=${slug}`);

  useEffect(() => {
    doFetch();
  }, []);

  if (error) return <PageNotFound />;

  return (
    <div>
      <div className="wrap wrap--sm">
        {!loading ? null : <div className="fade-in">Loading...</div>}
        {!page?._id ? null : (
          <>
            <PageTitle title={page.title} />
            <div style={{ margin: '120px 0', background: 'white', padding: '40px' }}>
              <ul>
                {Object.keys(page).map((el) => (
                  <li key={el} style={{ marginBottom: '10px' }}>
                    <span className="tag" style={{ marginRight: '10px' }}>
                      {el.toUpperCase()}
                    </span>{' '}
                    {page[el]}
                  </li>
                ))}
              </ul>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Page;
