import { ReactMarkdown } from 'react-markdown/lib/react-markdown';

import symbol from '../../../assets/symbol.svg';
import { Icon } from '../../../common/Icon/Icon';
import { Member, Tag } from '../../../core/models';
import CommunityCard from '../../Community/CommunityCard/CommunityCard';
import './UserProfile.css';

interface Props {
  user: Member;
  onClick: (param: string, val: string) => void;
}

function UserProfile({ user, onClick }: Props) {
  return (
    <div className="user-profile">
      <div className="user-profile__header">
        <div className="comm-showcase__item">
          <CommunityCard member={user} />
        </div>
        <div className="user-profile__info">
          <h2 className="user-profile__title h3">{`${user.firstname} ${user.lastname}`}</h2>
          {user.company && user.title && (
            <div className="user-profile__item">
              <p>
                {user.title} at{' '}
                <a
                  className="user-profile__anchor"
                  target="_blank"
                  href={user.company.url}
                  rel="noreferrer"
                >
                  {user.company.name}
                </a>
              </p>
            </div>
          )}
          {user.pronouns && (
            <div className="user-profile__item">
              <Icon.Smile className="icon-large" />
              {user.pronouns}
            </div>
          )}
          {!user.community_roles?.length ? null : (
            <div className="user-profile__item">
              <img className="icon-large" src={symbol} alt="logo" />
              <div className="user-profile__roles">
                {user.community_roles.map((el: Tag, i: number) => (
                  <div key={el._id}>
                    <button
                      className="user-profile__link"
                      type="button"
                      onClick={() => onClick('roles', el._id)}
                    >
                      {el.name}
                    </button>
                    {user.community_roles &&
                    user.community_roles.length > 1 &&
                    i < user.community_roles.length - 1 ? (
                      <>,&nbsp;</>
                    ) : (
                      ''
                    )}
                  </div>
                ))}
              </div>
            </div>
          )}
          {user.expertises && user.expertises.length > 0 && (
            <div className="user-profile__item">
              <Icon.Heart className="icon-large" />
              <div>
                Experience and interests
                <ul className="user-profile__list body-small">
                  {user.expertises.map((el) => (
                    <li key={el.name}>
                      <button
                        className="user-profile__link"
                        type="button"
                        onClick={() => onClick('expertises', el._id)}
                      >
                        {el.name}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
          {user.industries && user.industries.length > 0 && (
            <div className="user-profile__item">
              <Icon.Heart className="icon-large" />
              <div>
                Industries
                <ul className="user-profile__list body-small">
                  {user.industries.map((el) => (
                    <li key={el.name}>
                      <button
                        className="user-profile__link"
                        type="button"
                        onClick={() => onClick('industries', el._id)}
                      >
                        {el.name}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
          {user.current_location && (
            <div className="user-profile__item">
              <Icon.Location className="icon-large" />
              {user.current_location?.city}
            </div>
          )}
          {user.social &&
            Object.keys(user.social).map((el) => {
              const IconElement = Icon[el] || null;
              return (
                <a
                  className="user-profile__item"
                  href={user.social ? user.social[el as keyof Member['social']] : ''}
                  target="_blank"
                  rel="noreferrer"
                  key={el}
                >
                  <IconElement className="icon-large" /> Connect on {el}
                </a>
              );
            })}
        </div>
      </div>
      {!user.bio ? null : <ReactMarkdown className="markdown">{user.bio}</ReactMarkdown>}
    </div>
  );
}

export default UserProfile;
