/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from 'react-router-dom';

import { ReactComponent as CorpLogo } from '../../assets/B-Corp-Logo.svg';
import { ReactComponent as Logo } from '../../assets/logo-monochrome.svg';
import { mailTo } from '../../core/helpers';
import './Footer.css';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer__top">
        <div className="wrap wrap--md">
          <div className="footer__container">
            <div className="footer__col">
              <h2 className="footer__col-title h4">THNK home</h2>
              <address className="footer__address">
                Plantage Middenlaan 45
                <br />
                1018 DC Amsterdam
                <br />
                The Netherlands
                <br />
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    mailTo('amsterdam@thnk.org');
                  }}
                >
                  amsterdam@thnk.org
                </a>
                <br />
                <a
                  href="https://www.google.com/maps/place/Plantage+Middenlaan+45,+1018+DC+Amsterdam/@52.3655115,4.9127097,17z/data=!3m1!4b1!4m5!3m4!1s0x47c6099f50d4ec4b:0xe27408d4984a2edb!8m2!3d52.3655115!4d4.9148984?shorturl=1"
                  target="_blank"
                  rel="noreferrer"
                >
                  Show on Google maps
                </a>
              </address>
            </div>
            <div className="footer__col">
              <h2 className="footer__col-title h4">Follow</h2>
              <ul>
                <li>
                  <a
                    href="https://www.linkedin.com/company/969087"
                    target="_blank"
                    rel="noreferrer"
                  >
                    LinkedIn
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/thnkschool/" target="_blank" rel="noreferrer">
                    Instagram
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/THNKschool" target="_blank" rel="noreferrer">
                    Twitter
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.youtube.com/user/THNKAmsterdam?sub_confirmation=1"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Youtube
                  </a>
                </li>
                <li>
                  <a href="https://medium.com/@THNK" target="_blank" rel="noreferrer">
                    Medium
                  </a>
                </li>
              </ul>
            </div>
            <div className="footer__col">
              <h2 className="footer__col-title h4">Impact</h2>
              <a
                href="https://www.bcorporation.net/en-us/find-a-b-corp/company/thnk-holding-b-v/"
                target="_blank"
                rel="noreferrer"
              >
                <span className="sr-only">B Corp</span>
                <CorpLogo className="footer__corp" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="footer__bottom">
        <div className="wrap">
          <nav className="footer__bottom-nav">
            <ul className="footer__bottom-list">
              <li className="footer__bottom-item">
                <Link className="footer__bottom-link" to="/terms-and-conditions">
                  Terms and conditions
                </Link>
              </li>
              <li className="footer__bottom-item">
                <Link className="footer__bottom-link" to="/privacy-policy">
                  Privacy policy
                </Link>
              </li>
            </ul>
            <Link to="/">
              <span className="sr-only">Home page</span>
              <Logo className="footer__logo" />
            </Link>
          </nav>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
