import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ToggleSelect from '../../common/ToggleSelect/ToggleSelect';
import TextBlock from '../TextBlock/TextBlock';
import './Legal.css';

const TABS = [
  {
    name: 'Terms & Conditions',
    option: 'terms',
  },
  {
    name: 'Privacy policy',
    option: 'privacy',
  },
];

function Legal({ type }: { type: string }) {
  const navigate = useNavigate();
  const [content, setContent] = useState<string | null>(null);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    fetch(
      type === TABS[0].option
        ? './assets/TermsAndConditions.md'
        : type === TABS[1].option
        ? './assets/PrivacyPolicy.md'
        : '',
    )
      .then((res) => res.text())
      .then((text) => {
        setContent(text);
      });
  }, [type]);

  const renewConsent = useCallback(() => {
    const hsp = window?._hsp || [];
    hsp.push(['showBanner']);
  }, []);

  // Temp solution
  useEffect(() => {
    if (!ref.current) return;
    const renewLink = ref.current.querySelector('a[href="#"]');
    if (!renewLink) return;
    renewLink.addEventListener('click', (e) => {
      e.preventDefault();
      renewConsent();
    });
  }, [content]);

  const handleTabChange = useCallback((e: string) => {
    navigate(
      e === TABS[0].option
        ? '/terms-and-conditions'
        : e === TABS[1].option
        ? '/privacy-policy'
        : '/',
    );
  }, []);

  return (
    <section className="legal" ref={ref}>
      <div className="wrap wrap--xs">
        <div className="legal__header">
          <ToggleSelect
            selected={type === TABS[0].option ? 0 : type === TABS[1].option ? 1 : 0}
            items={TABS}
            onChange={handleTabChange}
          />
        </div>
      </div>
      {!content ? null : <TextBlock content={content} />}
    </section>
  );
}

export default Legal;
