import { useMemo } from 'react';

// TODO: delete images when cms is implemented
import img1 from '../../assets/usp1.png';
import img2 from '../../assets/usp2.png';
import img3 from '../../assets/usp3.png';
import { useScreenSizeContext } from '../../core/context/screenSize.context';
import { Image } from '../../core/models';
import './USP.css';

interface UspItem {
  img: Image;
  title: string;
  desc: string;
}

const DATA: UspItem[] = [
  {
    img: { uri: img1, alt: 'alt text' },
    title: '7000 +',
    desc: 'Diverse changemakers practice the THNK method around the world',
  },
  {
    img: { uri: img2, alt: 'alt text' },
    title: '60 +',
    desc: 'Replicable innovation, leadership, and business modelling frameworks',
  },
  {
    img: { uri: img3, alt: 'alt text' },
    title: '50 +',
    desc: 'International connections to make impact throughout the world',
  },
];

function USP() {
  const { isMobile } = useScreenSizeContext();

  const uspItems = useMemo(
    () =>
      DATA.map((item) => (
        <li className="usp__item" key={item.title}>
          <div className="usp__media">
            <img className="usp__img absolute-center" src={item.img.uri} alt={item.img.alt} />
          </div>
          <h2 className="usp__title h2">{item.title}</h2>
          <p className={`usp__desc${isMobile ? ' body-small' : ' body-large'}`}>{item.desc}</p>
        </li>
      )),
    [isMobile],
  );

  return (
    <section className="usp section">
      <div className="wrap wrap--md">
        <ul className="usp__list">{uspItems}</ul>
      </div>
    </section>
  );
}

export default USP;
