import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import UserProfile from '../../../Components/User/UserProfile/UserProfile';
import PageTitle from '../../../common/PageTitle/PageTitle';
import { useUserContext } from '../../../core/context/user.context';
import './Profile.css';

function Profile() {
  const { user } = useUserContext();
  const navigate = useNavigate();

  const goToCommunity = useCallback((val: string) => {
    navigate(`/community?filter=${val.toLowerCase().replace(/(,|-| )/g, '_')}`);
  }, []);

  return (
    <section className="profile">
      <div className="wrap wrap--xs">
        <PageTitle
          title="Profile page"
          backLabel="Back to overview"
          backPath="/user"
          ctaLabel="Edit"
          ctaPath="/profile-edit"
        />
      </div>
      <div className="wrap wrap--xs">
        {!user ? null : <UserProfile user={user} onClick={goToCommunity} />}
      </div>
    </section>
  );
}

export default Profile;
