/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import CustomButton from '../../../common/CustomButton/CustomButton';
import CustomCheckbox from '../../../common/CustomCheckbox/CustomCheckbox';
import CustomInput from '../../../common/CustomInput/CustomInput';
import CustomSelect from '../../../common/CustomSelect/CustomSelect';
import CustomTextarea from '../../../common/CustomTextarea/CustomTextarea';
import { Icon } from '../../../common/Icon/Icon';
import LocationInput from '../../../common/LocationInput/LocationInput';
import Message from '../../../common/Message/Message';
import ToggleSelect from '../../../common/ToggleSelect/ToggleSelect';
import { useUserContext } from '../../../core/context/user.context';
import { isEmpty } from '../../../core/helpers';
import useFetch from '../../../core/hooks/useFetch';
import { useFormCheckbox } from '../../../core/hooks/useFormCheckbox';
import { useFormInput } from '../../../core/hooks/useFormInput';
import { Tag } from '../../../core/models';
import './UserEditForm.css';

const COLORS = [
  {
    name: 'Dark blue',
    option: '#233b4b',
  },
  {
    name: 'Blue',
    option: '#218ce7',
  },
  {
    name: 'Red',
    option: '#ff3b4b',
  },
];

const COLOR_OPTIONS = COLORS.map((color) => color.option);

function UserEditForm() {
  const { user, forceUpdate } = useUserContext();
  const { doFetch: fetchExpertises } = useFetch(
    `${process.env.REACT_APP_API_URL}/expertises/published`,
  );
  const { doFetch: fetchIndustries } = useFetch(
    `${process.env.REACT_APP_API_URL}/industries/published`,
  );
  const { doFetch: updateUser, error } = useFetch(`${process.env.REACT_APP_API_URL}/user`);
  const { doFetch: uploadImage } = useFetch(`${process.env.REACT_APP_API_URL}/user/upload`);

  const [success, setSuccess] = React.useState(false);

  const fileInputRef = React.useRef<HTMLInputElement>(null);

  const [expertiseOptions, setExpertiseOptions] = React.useState([]);
  const [allExpertises, setAllExpertises] = React.useState([]);
  const [selectedExpertise, setSelectedExpertise] = React.useState<any>();

  const [industryOptions, setIndustryOptions] = React.useState([]);
  const [allIndustries, setAllIndustries] = React.useState([]);
  const [selectedIndustry, setSelectedIndustry] = React.useState<any>();

  const firstName = useFormInput(user?.firstname || '', (value) => !isEmpty(value));
  const lastName = useFormInput(user?.lastname || '', (value) => !isEmpty(value));
  const social = useFormInput(user?.social?.LinkedIn || '', (value) => !isEmpty(value));
  const occupation = useFormInput(user?.occupation || '', (value) => !isEmpty(value));
  const bio = useFormInput(user?.bio || '', (value) => !isEmpty(value));
  const birthLocationInput = useFormInput(
    `${
      user?.birth_location?.city
        ? `${
            user?.birth_location?.country
              ? `${user.birth_location.city}, ${user.birth_location.country}`
              : user.birth_location.city
          }`
        : user?.birth_location?.country
        ? user.birth_location.country
        : ''
    }`,
    (value) => !isEmpty(value),
  );
  const currentLocationInput = useFormInput(
    `${
      user?.current_location?.city
        ? `${
            user?.current_location?.country
              ? `${user.current_location.city}, ${user.current_location.country}`
              : user.current_location.city
          }`
        : user?.current_location?.country
        ? user.current_location.country
        : ''
    }`,
    (value) => !isEmpty(value),
  );
  const title = useFormInput(user?.title || '', (value) => !isEmpty(value));
  const privateInput = useFormCheckbox(user?.private === true);
  const companyName = useFormInput(user?.company?.name || '', (value) => !isEmpty(value));
  const companyUrl = useFormInput(user?.company?.url || '', (value) => !isEmpty(value));

  const [image, setImage] = React.useState<File>();

  const [birthLocation, setBirthLocation] = React.useState<any>({
    city: user?.birth_location?.city || '',
    country: user?.birth_location?.country || '',
  });
  const [currentLocation, setCurrentLocation] = React.useState<any>({
    city: user?.current_location?.city || '',
    country: user?.current_location?.country || '',
    coords: {
      lat: user?.current_location?.coords?.lat || 0,
      lng: user?.current_location?.coords?.lng || 0,
    },
  });

  const [expertises, setExpertises] = React.useState<string[]>([]);
  const [industries, setIndustries] = React.useState<string[]>([]);

  const [pronouns, setPronouns] = React.useState(user?.pronouns || '');

  const [backgroundColor, setBackgroundColor] = React.useState<string>(user?.bg_color || '#ff3b4b');

  const onColorChange = (option: string) => setBackgroundColor(option);

  React.useEffect(() => {
    if (user.expertises) setExpertises(user.expertises.map((el: Tag) => el.name));
    if (user.industries) setIndustries(user.industries.map((el: Tag) => el.name));
    if (user.pronouns) setPronouns(user.pronouns);
    if (user.bg_color) setBackgroundColor(user.bg_color);
    if (user.birth_location) setBirthLocation(user.birth_location);
    if (user.current_location) setCurrentLocation(user.current_location);
  }, [user]);

  const onExpertiseSelection = (e: any) => {
    const id = e.currentTarget.value;

    if (!id) {
      setSelectedExpertise(undefined);
    }

    const exp: any = expertiseOptions.find((expertise: any) => expertise._id === id);

    if (exp) {
      setSelectedExpertise(exp);
    }
  };

  const onExpertiseAdd = () => {
    if (selectedExpertise) {
      setExpertises((pre) => [...pre, selectedExpertise.name]);
      setSelectedExpertise(undefined);
    }
  };

  const onExpertiseRemove = (expertise: string) => {
    setExpertises((pre) => pre.filter((exp: any) => exp !== expertise));
  };

  const onIndustrySelection = (e: any) => {
    const id = e.currentTarget.value;

    if (!id) {
      setSelectedIndustry(undefined);
    }

    const exp: any = industryOptions.find((industry: any) => industry._id === id);

    if (exp) {
      setSelectedIndustry(exp);
    }
  };

  const onIndustryAdd = () => {
    if (selectedIndustry) {
      setIndustries((pre) => [...pre, selectedIndustry.name]);
      setSelectedIndustry(undefined);
    }
  };

  const onIndustryRemove = (industry: string) => {
    setIndustries((pre) => pre.filter((exp: any) => exp !== industry));
  };

  const onPictureClick = () => {
    if (fileInputRef && fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const onPictureSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files = [] } = e.currentTarget;

    if (files && files.length > 0) {
      const file = files[0];

      if (file) {
        setImage(file);
      }
    }
  };

  const onSubmit = async () => {
    if (image) {
      const formData = new FormData();

      formData.append('title', image.name);
      formData.append('alt', 'profile image');
      formData.append('is_public', 'true');
      formData.append('img', image);

      await uploadImage({
        method: 'POST',
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
        body: formData,
      });
    }

    const body: any = {
      bio: bio.value,
      expertises: allExpertises
        .filter((exp: any) => expertises.includes(exp.name))
        .map((exp: any) => exp._id),
      industries: allIndustries
        .filter((exp: any) => industries.includes(exp.name))
        .map((exp: any) => exp._id),
      firstname: firstName.value,
      lastname: lastName.value,
      occupation: occupation.value,
      pronouns: pronouns,
      bg_color: backgroundColor,
      private: privateInput.checked,
      title: title.value,
      social: {
        LinkedIn: social.value,
      },
      company: {
        name: companyName.value,
        url: companyUrl.value,
      },
      current_location: !currentLocationInput.value
        ? null
        : {
            city: currentLocation.city,
            country: currentLocation.country,
            coords: {
              lat: currentLocation.coords.lat,
              lng: currentLocation.coords.lng,
            },
          },
      birth_location: !birthLocationInput.value
        ? null
        : {
            city: birthLocation.city,
            country: birthLocation.country,
          },
    };

    await updateUser({
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${user.token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });

    setSuccess(true);
    forceUpdate();
  };

  React.useEffect(() => {
    fetchExpertises().then((res) => {
      if (res) {
        setAllExpertises(res.items);
      }
    });
    fetchIndustries().then((res) => {
      if (res) {
        setAllIndustries(res.items);
      }
    });
  }, []);

  React.useEffect(() => {
    if (allExpertises) {
      setExpertiseOptions(allExpertises.filter((item: any) => !expertises.includes(item.name)));
    }
    if (allIndustries) {
      setIndustryOptions(allIndustries.filter((item: any) => !industries.includes(item.name)));
    }
  }, [allExpertises, expertises, allIndustries, industries]);

  return (
    <div className="user-edit-form__container">
      <label className="input__label" htmlFor="user-edit-form__file">
        Profile picture
        <input
          ref={fileInputRef}
          id="user-edit-form__file"
          type="file"
          accept="image/*"
          onChange={onPictureSelect}
          style={{ display: 'none' }}
        />
      </label>
      <div
        className="user-edit-form__card"
        style={
          {
            '--bg-color': backgroundColor,
          } as any
        }
      >
        <CustomButton type="button" className="user-edit-form__card-face" onClick={onPictureClick}>
          {!user?.profile_pic?.uri && !image ? (
            <span style={{ color: backgroundColor === '#233b4b' ? 'white' : '' }}>
              Click to upload an image
            </span>
          ) : (
            <img
              className="user-edit-form__img"
              src={
                image
                  ? URL.createObjectURL(image)
                  : `${process.env.REACT_APP_API_URL}/uploads/${user.profile_pic?.uri}`
              }
              alt={user?.profile_pic?.alt}
            />
          )}
        </CustomButton>
      </div>
      <ToggleSelect
        selected={COLOR_OPTIONS.indexOf(backgroundColor)}
        label="Background color"
        items={COLORS}
        onChange={onColorChange}
      />
      <CustomInput
        id="firstName"
        placeholder="First name"
        name="firstName"
        type="text"
        input={firstName}
        label="First name"
      />
      <CustomInput
        id="lastName"
        placeholder="Last name"
        name="lastName"
        type="text"
        input={lastName}
        label="Last name"
      />
      <CustomSelect
        id="pronouns"
        placeholder="Pronouns"
        name="pronouns"
        label="Pronouns"
        options={[
          { value: 'She/her/hers', label: 'She/her/hers' },
          { value: 'He/him/his', label: 'He/him/his' },
          { value: 'They/them/theirs', label: 'They/them/theirs' },
          { value: 'Other', label: 'Other' },
        ]}
        onChange={(e) => setPronouns(e.currentTarget.value)}
        value={pronouns}
      />
      <CustomInput
        id="title"
        placeholder="Title"
        name="title"
        type="text"
        input={title}
        label="Title"
      />
      <CustomInput
        id="company-name"
        placeholder="Company name"
        name="company-name"
        type="text"
        input={companyName}
        label="Company name"
      />
      <CustomInput
        id="company-url"
        placeholder="Company URL"
        name="company-url"
        type="text"
        input={companyUrl}
        label="Company URL"
      />
      <LocationInput
        id="birth-location"
        placeholder="Where were you born?"
        name="birth-location"
        input={birthLocationInput}
        label="Where were you born?"
        onChange={(location) => setBirthLocation(location)}
      />
      <LocationInput
        id="current-location"
        placeholder="Where do you live?"
        name="current-location"
        input={currentLocationInput}
        label="Where do you live?"
        onChange={(location) => setCurrentLocation(location)}
      />
      <div className="user-edit-form__expertise_form">
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label className="input__label" style={{ marginBottom: 8 }}>
          What are your interests and experiences?
        </label>
        {expertises.map((expertise: any) => (
          <div key={expertise} className="user-edit-form__expertise_container">
            <input
              style={{ marginBottom: 8 }}
              className="input__field disabled"
              value={expertise}
              disabled
            />
            <CustomButton
              onClick={() => onExpertiseRemove(expertise)}
              type="button"
              className="user-edit-form__icon_button"
            >
              <Icon.Bin className="icon-large red" />
            </CustomButton>
          </div>
        ))}
        <div className="user-edit-form__expertise_container">
          <CustomSelect
            name="expertises"
            id="expertises"
            disabled={expertises.length === 3}
            className="user-edit-form__expertises_input"
            options={expertiseOptions.map((expertise: any) => ({
              value: expertise._id,
              label: expertise.name,
            }))}
            onChange={(e) => onExpertiseSelection(e)}
            placeholder="What are your interests and experiences?"
          />
          <CustomButton
            onClick={() => onExpertiseAdd()}
            type="button"
            disabled={expertises.length === 3}
            className="btn btn--dark user-edit-form__icon_button"
          >
            <Icon.Plus />
          </CustomButton>
        </div>
      </div>
      <div className="user-edit-form__expertise_form">
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label className="input__label" style={{ marginBottom: 8 }}>
          What industries are you in?
        </label>
        {industries.map((industry: any) => (
          <div key={industry} className="user-edit-form__expertise_container">
            <input
              style={{ marginBottom: 8 }}
              className="input__field disabled"
              value={industry}
              disabled
            />
            <CustomButton
              onClick={() => onIndustryRemove(industry)}
              type="button"
              className="user-edit-form__icon_button"
            >
              <Icon.Bin className="icon-large red" />
            </CustomButton>
          </div>
        ))}
        <div className="user-edit-form__expertise_container">
          <CustomSelect
            name="industries"
            id="industries"
            disabled={industries.length === 3}
            className="user-edit-form__expertises_input"
            options={industryOptions.map((industry: any) => ({
              value: industry._id,
              label: industry.name,
            }))}
            onChange={(e) => onIndustrySelection(e)}
            placeholder="What industries are you in?"
          />
          <CustomButton
            onClick={() => onIndustryAdd()}
            type="button"
            disabled={industries.length === 3}
            className="btn btn--dark user-edit-form__icon_button"
          >
            <Icon.Plus />
          </CustomButton>
        </div>
      </div>
      <CustomInput
        id="occupation"
        placeholder="Occupation"
        name="occupation"
        type="text"
        input={occupation}
        label="Occupation"
      />
      <CustomInput
        id="linedin-profile"
        placeholder="LinkedIn profile"
        name="linedin-profile"
        type="text"
        input={social}
        label="LinkedIn profile"
      />
      <CustomTextarea id="bio" placeholder="About you" name="bio" input={bio} label="About you" />
      <CustomCheckbox
        className="user-edit-form__checkbox"
        id="private"
        label="Private account"
        name="private"
        input={privateInput}
      />
      <CustomButton
        onClick={onSubmit}
        type="button"
        className="btn btn--dark user-edit-form__button"
      >
        Save changes
      </CustomButton>
      {success && <Message status="success" text="User updated successfully." />}
      {error && <Message status="error" text={error.message} />}
    </div>
  );
}

export default UserEditForm;
