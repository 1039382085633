import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import LoginForm from '../../../Components/User/LoginForm/LoginForm';
import PageTitle from '../../../common/PageTitle/PageTitle';
import { useUserContext } from '../../../core/context/user.context';

function Login() {
  const { user } = useUserContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (user.email) navigate('/');
  }, [user]);

  return (
    <div className="login">
      <div className="wrap wrap--xs">
        <PageTitle title="Log in" />
        <LoginForm />
      </div>
    </div>
  );
}

export default Login;
