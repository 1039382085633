/* eslint-disable @typescript-eslint/no-explicit-any */
import { useRef, useState } from 'react';

const useFetch = (url: string) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>('');
  const [result, setResult] = useState<any>(null);
  const abortController = useRef<any>(null);

  const doFetch = async (options?: RequestInit) => {
    setLoading(true);
    setError('');
    if (abortController.current) abortController.current.abort();

    try {
      abortController.current = new AbortController();
      const res = await fetch(url, { signal: abortController.current.signal, ...options });
      const resData = await res.json();

      if (!resData || resData.status === 'error' || resData.status === 'fail') {
        setError(resData);
        setLoading(false);
        setResult(null);
        return;
      }
      setError('');
      setResult(resData.data);
      setLoading(false);
      return resData.data;
    } catch (err: any) {
      if (err.name !== 'AbortError') {
        setError(err);
        setResult(null);
        setLoading(false);
      }
    }
  };

  return { loading, error, result, doFetch };
};

export default useFetch;
