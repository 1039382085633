import UserSettings from '../../../Components/User/UserSettings/UserSettings';
import PageTitle from '../../../common/PageTitle/PageTitle';

function Settings() {
  return (
    <section className="account-settings">
      <div className="wrap wrap--xs">
        <PageTitle title="Account settings" backLabel="Back to overview" backPath="/user" />
      </div>
      <div className="wrap wrap--xs">
        <UserSettings />
      </div>
    </section>
  );
}

export default Settings;
