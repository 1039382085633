import { useEffect } from 'react';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { useParams } from 'react-router-dom';

import InsightsBanner from '../../Components/Insights/InsightsBanner/InsightsBanner';
import PageTitle from '../../common/PageTitle/PageTitle';
import useFetch from '../../core/hooks/useFetch';
import { Tag } from '../../core/models';
import PageNotFound from '../PageNotFound/PageNotFound';
import './Article.css';

const BASE_URL = `${process.env.REACT_APP_API_URL}`;

function Article() {
  const { slug } = useParams();
  const {
    result: page,
    doFetch,
    loading,
    error,
  } = useFetch(`${BASE_URL}/article?slug=${slug}&populate=tags&populate=img`);

  useEffect(() => {
    doFetch();
  }, []);

  if (error) return <PageNotFound />;

  return (
    <article className="article">
      {!loading ? null : <div className="wrap wrap--md fade-in">Loading...</div>}
      {!page?._id ? null : (
        <>
          <div className="wrap wrap--md">
            <PageTitle
              title={page.title}
              backLabel="All insights"
              backPath="/insight"
              info={page.authors}
            />
          </div>
          <section className="article__content">
            <InsightsBanner
              text={page.summary}
              tags={page.tags.map((tag: Tag) => tag)}
              img={page.img}
            />
            <div className="wrap wrap--md">
              <ReactMarkdown className="article__body markdown">{page.body}</ReactMarkdown>
            </div>
          </section>
        </>
      )}
    </article>
  );
}

export default Article;
