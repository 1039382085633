import React from 'react';

import Alert from '../../common/Alert/Alert';

interface AlertOptions {
  message: string;
  status?: 'success' | 'warning' | 'error';
}

interface AlertInterface {
  setAlert: (options: AlertOptions) => void;
}

const initialValue = {
  setAlert() {},
};

const AlertContext = React.createContext<AlertInterface>(initialValue);

export const useAlert = () => React.useContext(AlertContext);

interface Props {
  children: React.ReactNode;
}

export function AlertContextProvider({ children }: Props) {
  const [options, setOptions] = React.useState<AlertOptions>();

  const setAlert = ({ message, status = 'success' }: AlertOptions) => {
    setOptions({
      message,
      status,
    });
  };

  React.useEffect(() => {
    if (options) {
      const timer = setTimeout(() => setOptions(undefined), 5 * 1000);

      return () => clearTimeout(timer);
    }
  }, [options]);

  return (
    <AlertContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        setAlert,
      }}
    >
      {options && <Alert status={options.status}>{options.message}</Alert>}
      {children}
    </AlertContext.Provider>
  );
}
