import { useEffect, useState } from 'react';

import { CustomCheckboxInteface } from '../models/inputs';

export const useFormCheckbox = (
  initialValue: boolean,
  validate: (newValue: boolean) => boolean = () => true,
): CustomCheckboxInteface => {
  const [checked, setChecked] = useState<boolean>(initialValue);
  const [isValid, setIsValid] = useState<boolean>(validate(checked));
  const [showError, setShowError] = useState<boolean>(false);

  useEffect(() => {
    setChecked(initialValue);
    setShowError(false);
    setIsValid(validate(initialValue));
  }, [initialValue]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setShowError(false);
    const newValue = e.target.checked;
    setChecked(newValue);
    setIsValid(validate(newValue));
  };

  return { checked, onChange, isValid, showError, setShowError, setChecked };
};
