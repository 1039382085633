import { useCallback, useRef } from 'react';

import { Member } from '../../../core/models';
import './CommunityMarker.css';

interface Props {
  member: Member;
  setHotMarker: React.Dispatch<React.SetStateAction<string | null>>;
  setSelectedMemberId: React.Dispatch<React.SetStateAction<string | null>>;
  openUserModal: (ref: HTMLButtonElement) => void;
}

function CommunityMarker({ member, setHotMarker, setSelectedMemberId, openUserModal }: Props) {
  const ref = useRef<HTMLButtonElement>(null);

  const selectMember = useCallback(() => {
    if (!ref.current) return;
    setSelectedMemberId(member._id);
    openUserModal(ref.current);
  }, [ref]);

  const setHot = useCallback(() => {
    setHotMarker(member._id);
  }, []);

  const clearHot = useCallback(() => {
    setHotMarker(null);
  }, []);
  return (
    <button
      className="member-marker"
      type="button"
      ref={ref}
      onClick={selectMember}
      onMouseEnter={setHot}
      onMouseLeave={clearHot}
      onFocus={setHot}
      onBlur={clearHot}
      style={(() => {
        const el = document.querySelector(`#community-member-${member._id}`);
        if (!el)
          return {
            backgroundColor: 'var(--primary)',
          };
        const color = window.getComputedStyle(el, ':after').backgroundColor || 'var(--primary)';
        return {
          backgroundColor: color,
        };
      })()}
    >
      <img
        className="member-marker__img"
        src={`${process.env.REACT_APP_API_URL}/uploads/${member.profile_pic?.uri}`}
        alt={member.profile_pic?.alt}
      />
    </button>
  );
}

export default CommunityMarker;
