import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

import { slugify } from '../../../core/helpers';
import { Member } from '../../../core/models';
import UserProfile from '../../User/UserProfile/UserProfile';
import './CommunityDetails.css';

interface Props {
  member: Member | undefined;
  closeUserModal: () => void;
  closeCallback: React.RefObject<{ f: () => void }>;
}

function CommunityDetails({ member, closeUserModal, closeCallback }: Props) {
  if (!member) return null;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();

  const closeModal = useCallback(
    (param: string, val: string) => {
      if (closeCallback.current)
        closeCallback.current.f = () => {
          setTimeout(() => {
            setSearchParams(!val.trim() ? '' : `${param}=${slugify(val)}`);
          }, 100);
          if (closeCallback.current) closeCallback.current.f = () => {};
        };
      closeUserModal();
    },
    [closeCallback.current],
  );

  return (
    <div
      className="community-details"
      style={(() => {
        const el = document.querySelector(`#community-member-${member._id}`);
        if (!el)
          return {
            '--bg': 'var(--primary)',
          } as React.CSSProperties;
        const color = window.getComputedStyle(el, ':after').backgroundColor || 'var(--primary)';
        return {
          '--bg': color,
        } as React.CSSProperties;
      })()}
    >
      <UserProfile user={member} onClick={closeModal} />
    </div>
  );
}

export default CommunityDetails;
