import React from 'react';
import { useNavigate } from 'react-router-dom';

import CustomButton from '../../../common/CustomButton/CustomButton';
import { useUserContext } from '../../../core/context/user.context';
import './UserDropdown.css';

function UserDropdown() {
  const { user, logout } = useUserContext();
  const navigate = useNavigate();

  const containerRef = React.useRef<HTMLDivElement>(null);

  const [open, setOpen] = React.useState<boolean>(false);

  const FIELDS = [
    {
      label: 'Account',
      onClick: () => navigate('/user'),
    },
    {
      label: 'Logout',
      onClick: () => {
        logout();
        navigate('/');
      },
    },
  ];

  React.useEffect(() => {
    if (containerRef && containerRef.current) {
      const element = containerRef.current;

      const onWindowClick = (e: MouseEvent) => {
        if (!element.contains(e.target as Node)) {
          setOpen(false);
        }
      };

      window.addEventListener('click', onWindowClick);

      return () => window.removeEventListener('click', onWindowClick);
    }
  }, [containerRef]);

  return (
    <div ref={containerRef}>
      <CustomButton
        type="button"
        onClick={() => setOpen((pre) => !pre)}
        className="user-dropdown__avatar"
      >
        {user?.firstname && user.firstname[0]}
        {user?.lastname && user.lastname[0]}
      </CustomButton>
      {open && (
        <div className="user-dropdown__container">
          {FIELDS.map((field, i) => (
            <button
              key={field.label}
              className={`user-dropdown__button ${
                i !== FIELDS.length - 1 ? 'user-dropdown__divider' : ''
              }`}
              type="button"
              onClick={() => {
                field.onClick();
                setOpen(false);
              }}
            >
              {field.label}
            </button>
          ))}
        </div>
      )}
    </div>
  );
}

export default UserDropdown;
