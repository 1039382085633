export function debounce<F extends (...args: Parameters<F>) => ReturnType<F>>(
  func: F,
  waitFor: number,
): (...args: Parameters<F>) => void {
  let timeout: NodeJS.Timeout;
  return (...args: Parameters<F>): void => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), waitFor);
  };
}

export function isEmpty(value: string) {
  return value.trim() === '';
}

export function isValidEmail(email: string) {
  const regex =
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

  return !!(email && regex.test(email));
}

export function isValidPassword(password: string) {
  // TODO: Change if needed
  return !!(password.trim().length >= 8);
}

export function slugify(str: string) {
  return str
    .toString()
    .normalize('NFKD')
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '-')
    .replace(/[^\w-]+/g, '')
    .replace(/_/g, '-')
    .replace(/--+/g, '-')
    .replace(/-$/g, '');
}

export function buildParams(data: object) {
  const params = new URLSearchParams();

  Object.entries(data).forEach(([key, value]) => {
    if (!value) return;
    if (Array.isArray(value)) {
      value.forEach((el) => params.append(key, el.toString()));
    } else {
      params.append(key, value.toString());
    }
  });

  return params.toString();
}

export function range(start: number, end: number) {
  const length = end - start + 1;
  return Array.from({ length }, (_, idx) => idx + start);
}

export const ToBool = (str?: string): boolean => {
  if (str === undefined) str = '';
  str = str.toString();
  switch (str.toLowerCase()) {
    case 'false':
    case 'no':
    case '0':
    case '': {
      return false;
    }

    default: {
      return true;
    }
  }
};

export function mailTo(email: string, subject?: string, body?: string) {
  let url;
  url = `mailto:${email}`;
  if (subject) url += `?subject=${subject}`;
  if (body) url += `&body=${body}`;
  window.open(url);
}
