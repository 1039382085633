import { useEffect, useRef } from 'react';
import { ReactNode } from 'react-markdown/lib/ast-to-react';

function useOutsideAlerter(ref: React.RefObject<HTMLElement>, onClick: () => void) {
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function handleClickOutside(event: any) {
      if (!ref) return;
      if (ref.current && !ref.current.contains(event.target)) {
        onClick();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
}

export default function OutsideAlerter({
  children,
  onClick,
}: {
  children: ReactNode;
  onClick: () => void;
}) {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, onClick);

  return <div ref={wrapperRef}>{children}</div>;
}
