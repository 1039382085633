import CommunityShowcase from '../../Components/Community/CommunityShowcase/CommunityShowcase';
import HomeBanner from '../../Components/HomeBanner/HomeBanner';
// import InsightsShowcase from '../../Components/Insights/InsightsShowcase/InsightsShowcase';
import Newsletter from '../../Components/Newsletter/Newsletter';
import USP from '../../Components/USP/USP';

function Home() {
  return (
    <>
      <HomeBanner />
      <USP />
      <CommunityShowcase />
      {/* <InsightsShowcase /> */}
      <Newsletter />
    </>
  );
}

export default Home;
