import TagsList from '../../../common/TagsList/TagsList';
import { Image, Tag } from '../../../core/models';
import './InsightsBanner.css';

interface Props {
  text: string;
  tags: Tag[];
  img: Image;
}

const IMG_URL = `${process.env.REACT_APP_API_URL}`;

function InsightsBanner({ text, tags, img }: Props) {
  return (
    <div className="insights-banner">
      <div className="wrap wrap--md">
        <div className="insights-banner__container">
          <div className="insights-banner__content">
            <p className="insights-banner__desc body-large">{text}</p>
            {!tags.length ? null : <TagsList tags={tags} />}
          </div>
          {!img ? null : (
            <img
              className="insights-banner__img"
              src={`${IMG_URL}/uploads/${img.uri}`}
              alt={img.alt}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default InsightsBanner;
