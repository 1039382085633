import { createContext, useContext, useEffect, useMemo, useState } from 'react';

interface Props {
  children: JSX.Element;
}

interface Theme {
  isScrolled: boolean;
}

const initialValue = {
  isScrolled: false,
};

const ThemeContext = createContext<Theme>(initialValue);

export const useThemeContext = () => useContext(ThemeContext);

export function ThemeContextProvider({ children }: Props) {
  const [isScrolled, setIsScrolled] = useState<boolean>(false);

  useEffect(() => {
    const handleScroll = () => {
      if (document.body.style.overflow === 'clip') return;
      setIsScrolled((document.documentElement.scrollTop || document.body.scrollTop) > 30);
    };
    document.addEventListener('scroll', handleScroll);

    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const value: Theme = useMemo(
    () => ({
      isScrolled,
    }),
    [isScrolled],
  );
  return <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>;
}
