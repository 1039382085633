import { Link } from 'react-router-dom';

import styles from './ComponentHeader.module.css';

interface Props {
  title: string;
  path: string;
  linkText: string;
}

function ComponentHeader({ title, path, linkText }: Props) {
  return (
    <div className={styles.header}>
      <h2 className="h2">{title}</h2>
      <Link className={`${styles.cta} btn btn--dark`} to={path}>
        {linkText}
      </Link>
    </div>
  );
}

export default ComponentHeader;
