import UserMenu from '../../../Components/User/UserMenu/UserMenu';
import PageTitle from '../../../common/PageTitle/PageTitle';

function Overview() {
  return (
    <section className="user-overview">
      <div className="wrap wrap--xs">
        <PageTitle title="Your account" />
        <UserMenu />
      </div>
    </section>
  );
}

export default Overview;
