import logo from '../../assets/logo.svg';
import CustomSelect from '../../common/CustomSelect/CustomSelect';
import { Icon } from '../../common/Icon/Icon';

function Business() {
  return (
    <section className="section">
      <div className="wrap">
        <img src={logo} alt="THNK" />
        <h1 className="h1">THNK</h1>
        <h2 className="h2">THNK</h2>
        <h3 className="h3">THNK</h3>
        <h4 className="h4">THNK</h4>
        <p className="body-large">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsam quisquam eveniet accusamus
          quaerat incidunt esse id enim, necessitatibus eligendi minima neque suscipit optio
          repudiandae a minus et deleniti nemo in.
        </p>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsam quisquam eveniet accusamus
          quaerat incidunt esse id enim, necessitatibus eligendi minima neque suscipit optio
          repudiandae a minus et deleniti nemo in.
        </p>
        <p className="body-small">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsam quisquam eveniet accusamus
          quaerat incidunt esse id enim, necessitatibus eligendi minima neque suscipit optio
          repudiandae a minus et deleniti nemo in.
        </p>
        <Icon.Bin className="icon" />
        <Icon.Chevron className="icon" />
        <Icon.Edit className="icon" />
        <Icon.External className="icon" />
        <Icon.Link className="icon" />
        <Icon.Plus className="icon" />
        <Icon.Search className="icon" />
        <div
          style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '8px' }}
        >
          <button className="btn btn--primary" type="button">
            Button label
          </button>
          <button className="btn btn--secondary" type="button">
            Button label
          </button>
          <button className="btn btn--dark" type="button">
            Button label
          </button>
          <button className="btn btn--light" type="button">
            Button label
          </button>
          <button className="link link--primary" type="button">
            Button label
          </button>
          <button className="link link--secondary" type="button">
            Button label
          </button>
          <button className="link link--dark" type="button">
            Button label
          </button>
          <button className="link link--light" type="button">
            Button label
          </button>
        </div>
        <CustomSelect
          id="select"
          name="select"
          placeholder="Placeholder"
          options={[
            {
              label: 'Option1',
              value: '1',
            },
            {
              label: 'Option2',
              value: '2',
            },
          ]}
        />
      </div>
    </section>
  );
}

export default Business;
