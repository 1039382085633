import { Link, NavLink } from 'react-router-dom';

import logo from '../../assets/logo.svg';
import symbol from '../../assets/symbol.svg';
import { useScreenSizeContext } from '../../core/context/screenSize.context';
import { useThemeContext } from '../../core/context/them.context';
import { useUserContext } from '../../core/context/user.context';
import UserDropdown from '../User/UserDropdown/UserDropdown';
import './Header.css';

interface NavLinks {
  path: string;
  label: string;
}

const NAV_LINKS: NavLinks[] = [
  {
    path: 'insight',
    label: 'Insights',
  },
  {
    path: 'community',
    label: 'Community',
  },
  // {
  //   path: 'business',
  //   label: 'Business',
  // },
];

function Header() {
  const { isMobile } = useScreenSizeContext();
  const { isScrolled } = useThemeContext();
  const { user } = useUserContext();

  return (
    <header className={`header${isScrolled ? ' scrolled' : ''}`}>
      <div className="wrap">
        <nav className="header__nav">
          <Link className="header__logo-link" to="/">
            <span className="sr-only">Home page</span>
            <img className="header__logo" src={isMobile ? symbol : logo} alt="logo" />
          </Link>
          <ul className="header__nav-list">
            {NAV_LINKS.map((link) => (
              <li className="header__nav-item" key={link.label}>
                <NavLink
                  className={({ isActive }) => `header__nav-link${isActive ? ' active' : ''}`}
                  to={link.path}
                >
                  {link.label}
                </NavLink>
              </li>
            ))}
            <li className="header__nav-item">
              {user.email ? (
                <UserDropdown />
              ) : (
                <NavLink
                  className={({ isActive }) => `header__nav-link${isActive ? ' active' : ''}`}
                  to="/login"
                >
                  Log in
                </NavLink>
              )}
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
}

export default Header;
