/* eslint-disable @typescript-eslint/no-explicit-any */
import { CustomInputInteface } from '../../core/models/inputs';

interface Props {
  id: string;
  name: string;
  label?: string;
  max?: number;
  placeholder: string;
  input: CustomInputInteface;
}

function CustomTextarea({ id, name, label, placeholder, input, max = 250 }: Props) {
  return (
    <div className="input">
      <div className="input__header">
        {!label ? null : (
          <label className="input__label" htmlFor={id}>
            {label}
          </label>
        )}
      </div>
      <div className="input__wrapper">
        <textarea
          id={id}
          name={name}
          value={input.value}
          onChange={input.onChange as any}
          placeholder={placeholder}
          className="input__field"
          maxLength={max}
        />
      </div>
    </div>
  );
}

export default CustomTextarea;
