import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import useFetch from '../../core/hooks/useFetch';

function VerifyEmail() {
  const { verificationId = '' } = useParams();
  const { doFetch } = useFetch(
    `${process.env.REACT_APP_API_URL}/user/verify-email/${verificationId}`,
  );
  const navigate = useNavigate();

  const verify = React.useCallback(async () => {
    const res = await doFetch({
      method: 'GET',
    });

    if (res) {
      navigate(`/login?email=${encodeURIComponent(res)}`);
      return;
    }

    navigate('/');
  }, []);

  React.useEffect(() => {
    verify();
  }, []);

  return <div />;
}

export default VerifyEmail;
