import { Link } from 'react-router-dom';

import { Icon } from '../Icon/Icon';
import './PageTitle.css';

interface Props {
  title: string;
  backLabel?: string;
  backPath?: string;
  info?: string;
  ctaLabel?: string;
  ctaPath?: string;
}

function PageTitle({ title, backLabel, backPath, info, ctaLabel, ctaPath }: Props) {
  return (
    <section className="page-title">
      {backLabel && backPath ? (
        <Link className="page-title__back" to={backPath}>
          <Icon.Chevron className="page-title__back-icon icon" />
          {backLabel}
        </Link>
      ) : null}
      <div className="page-title__content">
        <h1 className="page-title__title h2">{title}</h1>
        {ctaLabel && ctaPath ? (
          <Link className="page-title__cta btn btn--dark" to={ctaPath}>
            {ctaLabel}
          </Link>
        ) : null}
      </div>
      {info && <div className="page-title__info body-large">{info}</div>}
    </section>
  );
}

export default PageTitle;
