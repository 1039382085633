import { ReactComponent as Bin } from './icons/bin.svg';
import { ReactComponent as Check } from './icons/check.svg';
import { ReactComponent as Chevron } from './icons/chevron.svg';
import { ReactComponent as Edit } from './icons/edit.svg';
import { ReactComponent as Exclamation } from './icons/exclamation.svg';
import { ReactComponent as External } from './icons/external.svg';
import { ReactComponent as EyeClosed } from './icons/eye-closed.svg';
import { ReactComponent as Eye } from './icons/eye.svg';
import { ReactComponent as Heart } from './icons/heart.svg';
import { ReactComponent as Link } from './icons/link.svg';
import { ReactComponent as LinkedIn } from './icons/linkedIn.svg';
import { ReactComponent as Location } from './icons/location.svg';
import { ReactComponent as Plus } from './icons/plus.svg';
import { ReactComponent as Search } from './icons/search.svg';
import { ReactComponent as Security } from './icons/security.svg';
import { ReactComponent as Settings } from './icons/settings.svg';
import { ReactComponent as Smile } from './icons/smile.svg';
import { ReactComponent as Twitter } from './icons/twitter.svg';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Icon: any = {
  Bin,
  Check,
  Chevron,
  Edit,
  Exclamation,
  External,
  EyeClosed,
  Eye,
  Heart,
  Link,
  LinkedIn,
  Location,
  Plus,
  Search,
  Smile,
  Twitter,
  Security,
  Settings,
};
