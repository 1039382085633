import React from 'react';

import InviteForm from '../../Components/InviteForm/InviteForm';
import PageTitle from '../../common/PageTitle/PageTitle';

function Invite() {
  return (
    <div className="invite">
      <div className="wrap wrap--xs">
        <PageTitle title="Create your account" />
      </div>
      <div className="wrap wrap--xs">
        <InviteForm />
      </div>
    </div>
  );
}

export default Invite;
