import { useScreenSizeContext } from '../../core/context/screenSize.context';
import './HomeBanner.css';

function HomeBanner() {
  const { isMobile } = useScreenSizeContext();

  return (
    <section className="home-banner">
      <div className="wrap wrap--sm">
        <h1 className="home-banner__title h1">
          Lead with
          <br />
          the world <span className="space">in</span>
          <span className="highlight">
            mind
            <span className="highlight__red" />
          </span>
        </h1>
        <p className={`home-banner__desc${isMobile ? '' : ' h4'}`}>
          THNK supports leaders to develop innovative solutions to the world&apos;s greatest
          societal challenges.
        </p>
      </div>
    </section>
  );
}

export default HomeBanner;
