import React from 'react';
import { useNavigate } from 'react-router-dom';

import CustomButton from '../../../common/CustomButton/CustomButton';
import CustomForm from '../../../common/CustomForm/CustomForm';
import CustomInput from '../../../common/CustomInput/CustomInput';
import { Icon } from '../../../common/Icon/Icon';
import Message from '../../../common/Message/Message';
import { PASSWORD_REGEX } from '../../../core/constants';
import { useUserContext } from '../../../core/context/user.context';
import { isEmpty } from '../../../core/helpers';
import useFetch from '../../../core/hooks/useFetch';
import { useFormInput } from '../../../core/hooks/useFormInput';
import useModal from '../../../core/hooks/useModal';
import { CurrentUser } from '../../../core/models';
import { CustomInputInteface } from '../../../core/models/inputs';
import './UserSettings.css';

interface IUserSettingsForm {
  id: string;
  label: string;
  valueKey?: 'email';
  editable: boolean;
  value?: string;
  error?: {
    message: string;
  };
  inputs?: {
    id: string;
    type: string;
    name: string;
    placeholder: string;
    label: string;
    input: CustomInputInteface;
    link?: {
      text: string;
      href: string;
    };
    emptyMessage: string;
    invalidMessage: string;
  }[];
  onSubmit?(): void;
}

function UserSettings() {
  const { user, setUser, logout } = useUserContext();
  const { doFetch: deleteUser, error: userDeletionError } = useFetch(
    `${process.env.REACT_APP_API_URL}/user/delete`,
  );

  const navigate = useNavigate();

  const [selected, setSelected] = React.useState<number>();

  const { open, close, modal } = useModal();

  const newPassword = useFormInput('', (value) => !isEmpty(value) && PASSWORD_REGEX.test(value));
  const oldPassword = useFormInput('', (value) => !isEmpty(value) && PASSWORD_REGEX.test(value));

  const btnRef = React.useRef(null);

  const [error, setError] = React.useState();
  const [success, setSuccess] = React.useState<boolean>(false);

  const {
    doFetch: doChangePasswordFetch,
    error: changePasswordError,
    loading,
  } = useFetch(`${process.env.REACT_APP_API_URL}/user/change-password`);

  const openModal = React.useCallback(() => {
    if (!btnRef.current) return;
    open(btnRef.current);
  }, [btnRef.current]);

  const onUserDelete = async () => {
    await deleteUser({
      method: 'POST',
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    });
    if (!userDeletionError) {
      logout();
      navigate('/');
    }
  };

  const onPasswordChange = async () => {
    const res = await doChangePasswordFetch({
      method: 'POST',
      headers: {
        Authorization: `Bearer ${user.token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        old: oldPassword.value,
        new: newPassword.value,
      }),
    });
    if (res) {
      setSuccess(true);
      setUser((pre: CurrentUser) => ({ ...pre, token: res }));
    }
  };

  const forms: IUserSettingsForm[] = [
    {
      id: 'email-address-form',
      label: 'Email address',
      valueKey: 'email',
      editable: false,
    },
    {
      id: 'password-form',
      label: 'Password',
      value: '***********',
      editable: true,
      error: changePasswordError,
      inputs: [
        {
          id: 'old-password',
          type: 'password',
          name: 'old-password',
          placeholder: 'Enter your old password',
          label: 'Old password',
          link: { text: 'Forgot your password?', href: '/forgot-password' },
          emptyMessage: 'Please fill in your old password',
          invalidMessage:
            'Please fill in a valid password, minimum eight characters, one uppercase letter, one lowercase letter, one digit and one special character.',
          input: oldPassword,
        },
        {
          id: 'new-password',
          type: 'password',
          name: 'new-password',
          placeholder: 'Enter your new password',
          label: 'New password',
          emptyMessage: 'Please fill in your new password',
          invalidMessage:
            'Please fill in a valid password, minimum eight characters, one uppercase letter, one lowercase letter, one digit and one special character.',
          input: newPassword,
        },
      ],
      onSubmit: onPasswordChange,
    },
  ];

  React.useEffect(() => {
    if (changePasswordError && changePasswordError.message) {
      setSuccess(false);

      setError(changePasswordError.message);
    }

    if (success) {
      setError(undefined);
    }
  }, [changePasswordError, success]);

  return (
    <div className="user-settings__container">
      {forms.map((form, i) => (
        <div
          className={`user-settings__form_container ${
            i !== forms.length - 1 ? 'user-settings__underline' : ''
          }`}
          key={form.id}
        >
          <div
            className={`user-settings__form  ${
              selected !== undefined && selected !== i ? 'user-settings__disabled' : ''
            }`}
          >
            <div className="user-settings__input_container">
              <p className="user-settings__label">{form.label}</p>
              {selected !== i && (
                <p>{form.value ? form.value : form.valueKey ? user[form.valueKey] : ''}</p>
              )}
            </div>
            {form.editable && (
              <CustomButton
                type="button"
                className="user-settings__edit_button"
                onClick={() => {
                  if (!Number.isNaN(selected) && selected === i) {
                    setSuccess(false);
                    setError(undefined);
                    setSelected(undefined);
                  } else {
                    setSelected(i);
                  }
                }}
                disabled={selected !== undefined ? selected !== i : false}
              >
                {selected === i ? 'Cancel' : 'Edit'}
              </CustomButton>
            )}
          </div>
          {selected === i && (
            <CustomForm
              className="user-settings__edit_form"
              inputs={form.inputs?.map((record) => record.input) || []}
              onSubmit={form.onSubmit}
            >
              {form.inputs?.map((input) => (
                <CustomInput
                  key={input.id}
                  id={input.name}
                  name={input.name}
                  placeholder={input.placeholder}
                  input={input.input}
                  emptyMessage={input.emptyMessage}
                  invalidMessage={input.invalidMessage}
                  label={input.label}
                  type={input.type}
                  link={input.link}
                />
              ))}
              <CustomButton
                className="user-settings__cta btn btn--dark"
                type="submit"
                loading={loading}
              >
                Save
              </CustomButton>
            </CustomForm>
          )}
        </div>
      ))}
      {error && <Message text={error} status="error" />}
      {success && <Message text="Successfully updated" status="success" />}
      <button
        type="button"
        className={`user-settings__delete_account_button ${
          selected !== undefined ? 'user-settings__disabled' : ''
        }`}
        disabled={selected !== undefined}
        ref={btnRef}
        onClick={openModal}
      >
        Delete my account
      </button>
      {modal({
        children: (
          <div className="user-settings__modal_container">
            <div className="user-settings__modal_title">
              <div>Delete your account</div>
              <button className="modal__close" onClick={close} type="button">
                <Icon.Plus className="icon-large" />
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            <div className="user_settings__modal_content">
              <p className="h3">Are you sure that you want to delete your account?</p>
              <p>All your personal information will be deleted.</p>
            </div>
            <div className="user_settings__modal_buttons">
              <CustomButton
                onClick={onUserDelete}
                className="user_settings__modal_button"
                type="button"
              >
                Yes, delete account
              </CustomButton>
              <CustomButton
                onClick={() => close()}
                className="user_settings__modal_button"
                type="button"
              >
                No, keep account
              </CustomButton>
            </div>
          </div>
        ),
        side: 'center',
        keepMounted: true,
      })}
    </div>
  );
}

export default UserSettings;
