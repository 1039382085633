import { Route, Routes } from 'react-router-dom';

import App from './App';
import Legal from './Components/Legal/Legal';
import ProtectedRoute from './Components/ProtectedRoute';
import UserLayout from './Components/User/UserLayout/UserLayout';
import LandingApp from './LandingApp';
import Article from './Pages/Article/Article';
import Business from './Pages/Business/Business';
import CommunityPage from './Pages/Community/CommunityPage';
import Home from './Pages/Home/Home';
import Insights from './Pages/Insights/Insights';
import Invite from './Pages/Invite/Invite';
import LandingPage from './Pages/LandingPage/LandingPage';
import Page from './Pages/Page/Page';
import PageNotFound from './Pages/PageNotFound/PageNotFound';
import AccountEdit from './Pages/User/Edit/Edit';
import Login from './Pages/User/Login/Login';
import Overview from './Pages/User/Overview/Overview';
import Profile from './Pages/User/Profile/Profile';
import AccountSettings from './Pages/User/Settings/Settings';
import VerifyEmail from './Pages/VerifyEmail/VerifyEmail';
import { useUserContext } from './core/context/user.context';
import { ToBool } from './core/helpers';

function AppRoutes() {
  const { user } = useUserContext();
  return (
    <Routes>
      {ToBool(process.env.REACT_APP_SHOW_LANDING_PAGE) ? (
        <Route path="/" element={<LandingApp />}>
          <Route index element={<LandingPage />} />
          <Route path="*" element={<PageNotFound />} />
        </Route>
      ) : (
        <>
          <Route path="/" element={<App />}>
            <Route index element={<Home />} />
            <Route element={<UserLayout />}>
              <Route path="login/" element={<Login />} />
              <Route element={<ProtectedRoute user={user} />}>
                <Route path="user/">
                  <Route index element={<Overview />} />
                </Route>
                <Route path="profile/" element={<Profile />} />
                <Route path="profile-edit/" element={<AccountEdit />} />
                <Route path="account-settings/" element={<AccountSettings />} />
              </Route>
            </Route>
            <Route path="user/invited/:invitationId" element={<Invite />} />
            <Route path="user/verify-email/:verificationId" element={<VerifyEmail />} />
            <Route path="insight/">
              <Route index element={<Insights />} />
              <Route path=":slug/" element={<Article />} />
            </Route>
            <Route path="community/" element={<CommunityPage />} />
            <Route path="business/" element={<Business />} />
            <Route path="terms-and-conditions/" element={<Legal type="terms" />} />
            <Route path="privacy-policy/" element={<Legal type="privacy" />} />
            <Route path=":slug/" element={<Page />} />
            <Route path="*" element={<PageNotFound />} />
          </Route>
          <Route path="/landing" element={<LandingApp />}>
            <Route index element={<LandingPage />} />
          </Route>
        </>
      )}
    </Routes>
  );
}

export default AppRoutes;
