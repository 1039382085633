import { useEffect } from 'react';

import './HubSpotForm.css';

interface Props {
  portalId: string;
  formId: string;
  region: string;
}

function HubSpotForm({ portalId, formId, region }: Props) {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = '//js.hsforms.net/forms/embed/v2.js';
    document.body.appendChild(script);
    const listener = () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region,
          portalId,
          formId,
          target: '#hubspotForm',
        });
      }
    };
    script.addEventListener('load', listener);

    return () => {
      script.removeEventListener('load', listener);
    };
  }, []);
  return <div id="hubspotForm" />;
}

export default HubSpotForm;
