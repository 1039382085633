import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import CustomButton from '../../common/CustomButton/CustomButton';
import CustomCheckbox from '../../common/CustomCheckbox/CustomCheckbox';
import CustomForm from '../../common/CustomForm/CustomForm';
import CustomInput from '../../common/CustomInput/CustomInput';
import { Icon } from '../../common/Icon/Icon';
import LocationInput from '../../common/LocationInput/LocationInput';
import Message from '../../common/Message/Message';
import { PASSWORD_REGEX } from '../../core/constants';
import { isEmpty } from '../../core/helpers';
import useFetch from '../../core/hooks/useFetch';
import { useFormCheckbox } from '../../core/hooks/useFormCheckbox';
import { useFormInput } from '../../core/hooks/useFormInput';
import { Location } from '../../core/models';
import './InviteForm.css';

function InviteForm() {
  const { invitationId = '' } = useParams();
  const navigate = useNavigate();

  const { doFetch: checkInvitationLink } = useFetch(
    `${process.env.REACT_APP_API_URL}/user/invited-email/${invitationId}`,
  );
  const {
    doFetch: registerUser,
    error,
    result,
  } = useFetch(`${process.env.REACT_APP_API_URL}/user/register`);

  const fileInputRef = React.useRef<HTMLInputElement>(null);

  const [email, setEmail] = React.useState();
  const [birthLocation, setBirthLocation] = React.useState<Location>();
  const [currentLocation, setCurrentLocation] = React.useState<Location>();

  const firstName = useFormInput('', (value) => !isEmpty(value));
  const lastName = useFormInput('', (value) => !isEmpty(value));
  const birthLocationInput = useFormInput('', (value) => !isEmpty(value));
  const currentLocationInput = useFormInput('', (value) => !isEmpty(value));
  const password = useFormInput('', (value) => !isEmpty(value) && PASSWORD_REGEX.test(value));
  const repeatPassword = useFormInput('', (value) => !isEmpty(value) && value === password.value);
  const emailInput = useFormInput(email || '', (value) => !isEmpty(value));
  const termsCheckbox = useFormCheckbox(false, (value) => value);

  const [image, setImage] = React.useState<File>();

  const [nonInputData, setNonInputData] = React.useState({});
  const [connectedId, setConnectedId] = React.useState<string>();

  const onPictureSelect = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { files = [] } = e.currentTarget;

    if (files && files.length > 0) {
      const file = files[0];

      if (file) {
        setImage(file);
      }
    }
  };

  const onPictureClick = () => {
    if (fileInputRef && fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const checkInvitation = React.useCallback(async () => {
    const res = await checkInvitationLink({
      method: 'GET',
    });

    if (!res) {
      return navigate('/');
    }

    if (res) {
      setNonInputData({
        ...res,
      });
    }
    if (res.email) {
      setEmail(res.email);
    }
    if (res.firstname) {
      firstName.setValue(res.firstname);
    }
    if (res.lastname) {
      lastName.setValue(res.lastname);
    }
    if (res._id) {
      setConnectedId(res._id);
    }
  }, []);

  const onSubmit = async () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const body: any = {
      ...nonInputData,
      email: email,
      firstname: firstName.value,
      lastname: lastName.value,
      password: password.value,
    };

    if (birthLocationInput && birthLocation && birthLocation.country && birthLocation.city) {
      body.birth_location = {
        country: birthLocation.country,
        city: birthLocation.city,
      };
    }

    if (
      currentLocationInput &&
      currentLocation &&
      currentLocation.country &&
      currentLocation.city
    ) {
      body.current_location = {
        country: currentLocation.country,
        city: currentLocation.city,
        coords: {
          lat: currentLocation.coords?.lat,
          lng: currentLocation.coords?.lng,
        },
      };
    }

    if (connectedId) {
      body.connected_id = connectedId;
    }

    await registerUser({
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });
  };

  React.useEffect(() => {
    checkInvitation();
  }, []);

  return result ? (
    <Message
      status="success"
      text="User successfully created. Check your inbox for confirmation email"
    />
  ) : (
    <div className="invite-form__container">
      <CustomForm
        onSubmit={onSubmit}
        inputs={[firstName, lastName, password, repeatPassword, termsCheckbox]}
        className="invite-form"
      >
        <CustomInput
          type="email"
          id="email"
          name="email"
          input={emailInput}
          placeholder="Your email"
          label="Your email"
          disabled
        />
        <CustomInput
          type="text"
          id="firstName"
          name="firstName"
          input={firstName}
          placeholder="Your first name"
          label="First name"
          emptyMessage="First name is required"
        />
        <CustomInput
          type="text"
          id="lastName"
          name="lastName"
          input={lastName}
          placeholder="Your last name"
          label="Last name"
          emptyMessage="Last name is required"
        />
        <label className="input__label" htmlFor="invite-form__file">
          Profile picture
          <input
            ref={fileInputRef}
            id="invite-form__file"
            type="file"
            accept="image/*"
            onChange={onPictureSelect}
            style={{ display: 'none' }}
          />
        </label>
        <div className="invite-form__image_upload_input">
          <div className="invite-form__card">
            <CustomButton type="button" className="invite-form__card-face" onClick={onPictureClick}>
              {image ? (
                <img className="invite-form__img" src={URL.createObjectURL(image)} alt="profile" />
              ) : (
                <div className="invite-form__img">Click here to upload your image</div>
              )}
            </CustomButton>
          </div>
          {image && (
            <CustomButton
              type="button"
              className="invite-form__image_removal_button"
              onClick={() => setImage(undefined)}
            >
              <Icon.Bin className="icon-large" />
            </CustomButton>
          )}
        </div>
        <LocationInput
          id="birth-location"
          name="birth-location"
          input={birthLocationInput}
          placeholder="Where were you born?"
          label="Where were you born?"
          onChange={(e) => setBirthLocation(e)}
        />
        <LocationInput
          id="current-location"
          name="current-location"
          input={currentLocationInput}
          placeholder="Where do you live?"
          label="Where do you live?"
          onChange={(e) => setCurrentLocation(e)}
          autoFill={false}
        />
        <CustomInput
          type="password"
          id="password"
          name="password"
          input={password}
          placeholder="Your password"
          label="Your password"
          emptyMessage="Password is required"
          invalidMessage="Please fill in a valid password, minimum eight characters, one uppercase letter, one lowercase letter, one digit and one special character."
          autoFill={false}
        />
        <CustomInput
          type="password"
          id="password-repeat"
          name="password-repeat"
          input={repeatPassword}
          placeholder="Repeat your password"
          label="Repeat your password"
          emptyMessage="It's required to repeat your password"
          autoFill={false}
        />
        <CustomCheckbox
          id="terms-checkbox"
          name="terms-checkbox"
          input={termsCheckbox}
          label="I agree with the Privacy Statement and Terms & Conditions."
          invalidMessage="Required"
        />
        <CustomButton type="submit" className="invite-form__button btn btn--dark">
          Create your account
        </CustomButton>
      </CustomForm>
      {error && <Message status="error" text={error.message || ''} />}
    </div>
  );
}

export default InviteForm;
