import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import CustomInput from '../../common/CustomInput/CustomInput';
import CustomSelect from '../../common/CustomSelect/CustomSelect';
import { Icon } from '../../common/Icon/Icon';
import Message from '../../common/Message/Message';
import Pager from '../../common/Pager/Pager';
import useFetch from '../../core/hooks/useFetch';
import { useFormInput } from '../../core/hooks/useFormInput';
import useModal from '../../core/hooks/useModal';
import { Member, Tag } from '../../core/models';
import './Community.css';
import CommunityDetails from './CommunityDetails/CommunityDetails';
import CommunityItem from './CommunityItem/CommunityItem';
import CommunityMap from './CommunityMap/CommunityMap';

const BASE_URL = `${process.env.REACT_APP_API_URL}`;

function Community() {
  const [searchParams, setSearchParams] = useSearchParams();
  const page = useMemo(() => searchParams.get('page') || '1', [searchParams]);
  const [hotMarker, setHotMarker] = useState<string | null>(null);
  const [selectedMemberId, setSelectedMemberId] = useState<string | null>(null);
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const expertises = useMemo(() => searchParams.get('expertises') || '', [searchParams]);
  const roles = useMemo(() => searchParams.get('roles') || '', [searchParams]);
  const classes = useMemo(() => searchParams.get('classes') || '', [searchParams]);
  const industries = useMemo(() => searchParams.get('industries') || '', [searchParams]);
  const searchValue = useMemo(() => searchParams.get('search') || '', [searchParams]);
  const search = useFormInput(searchValue);
  const timeout = useRef<ReturnType<typeof setInterval> | null>(null);
  const { result, doFetch, loading, error } = useFetch(
    `${BASE_URL}/users/members?per_page=10&page=${page}${
      !searchValue
        ? ''
        : `&current_location.city=${searchValue}&firstname=${searchValue}&lastname=${searchValue}&company.name=${searchValue}&occupation=${searchValue}&title=${searchValue}`
    }${!expertises ? '' : `&expertises=${expertises}`}${!roles ? '' : `&community_roles=${roles}`}${
      !classes ? '' : `&member_classes=${classes}`
    }${!industries ? '' : `&industries=${industries}`}`,
  );
  const { result: fetchedExpertises, doFetch: doExpertisesFetch } = useFetch(
    `${BASE_URL}/expertises/published`,
  );
  const { result: fetchedCommunityRoles, doFetch: doCommunityRolesFetch } = useFetch(
    `${BASE_URL}/community-roles/published`,
  );
  const { result: fetchedMemberClasses, doFetch: doMemberClassesFetch } = useFetch(
    `${BASE_URL}/member-classes/published`,
  );
  const { result: fetchedIndustries, doFetch: doIndustriesFetch } = useFetch(
    `${BASE_URL}/industries/published`,
  );
  const {
    modal: userModal,
    open: openUserModal,
    close: closeUserModal,
    closeCallback,
  } = useModal();

  useEffect(() => {
    doExpertisesFetch();
    doCommunityRolesFetch();
    doMemberClassesFetch();
    doIndustriesFetch();
  }, []);

  useEffect(() => {
    doFetch();
  }, [searchValue, page, expertises, roles, classes, industries]);

  const selectedMember = useMemo(
    () => result?.items?.find((el: Member) => el._id === selectedMemberId),
    [selectedMemberId],
  );

  useEffect(() => {
    if (timeout.current) clearTimeout(timeout.current);
    searchParams.delete('page');
    timeout.current = setTimeout(() => {
      if (search.value) {
        searchParams.set('search', search.value);
      } else {
        searchParams.delete('search');
      }
      setSearchParams(searchParams);
    }, 500);
    return () => {
      if (timeout.current) clearTimeout(timeout.current);
    };
  }, [search.value]);

  const handleFilterBtnClick = useCallback(() => {
    setShowFilters((prevState) => !prevState);
  }, []);

  const expertiseOptions = useMemo(
    () =>
      !fetchedExpertises
        ? []
        : fetchedExpertises.items.map((el: Tag) => ({ value: el._id, label: el.name })),
    [fetchedExpertises],
  );

  const rolesOptions = useMemo(
    () =>
      !fetchedCommunityRoles
        ? []
        : fetchedCommunityRoles.items.map((el: Tag) => ({ value: el._id, label: el.name })),
    [fetchedCommunityRoles],
  );

  const classesOptions = useMemo(
    () =>
      !fetchedMemberClasses
        ? []
        : fetchedMemberClasses.items.map((el: Tag) => ({ value: el._id, label: el.name })),
    [fetchedMemberClasses],
  );

  const industriesOptions = useMemo(
    () =>
      !fetchedIndustries
        ? []
        : fetchedIndustries.items.map((el: Tag) => ({ value: el._id, label: el.name })),
    [fetchedIndustries],
  );

  const handleFilters = useCallback(
    (e: React.FormEvent<HTMLSelectElement>) => {
      searchParams.delete('page');
      if (e.currentTarget.value) {
        searchParams.set(e.currentTarget.name, e.currentTarget.value);
      } else {
        searchParams.delete(e.currentTarget.name);
      }
      setSearchParams(searchParams);
    },
    [searchValue],
  );

  return (
    <section className="community">
      <div className="community__lister">
        <h1 className="community__title h2">The community</h1>
        <div className={`community__header${showFilters ? ' community__header--filters' : ''}`}>
          <CustomInput
            id="member-filter"
            type="text"
            name="member-filter"
            placeholder="Find THNKer"
            input={search}
            icon={<Icon.Search />}
          />
          <button
            className="community__filter-btn btn btn--light"
            onClick={handleFilterBtnClick}
            type="button"
          >
            <Icon.Plus className={`icon-large${showFilters ? ' rotate-45' : ''}`} />
            Filter
          </button>
        </div>
        {!showFilters ? null : (
          <div className="community__filters">
            <CustomSelect
              id="expertises"
              name="expertises"
              placeholder="Expertises"
              options={expertiseOptions}
              value={expertises}
              onChange={handleFilters}
            />
            <CustomSelect
              id="roles"
              name="roles"
              placeholder="Roles"
              options={rolesOptions}
              value={roles}
              onChange={handleFilters}
            />
            <CustomSelect
              id="classes"
              name="classes"
              placeholder="Classes"
              options={classesOptions}
              value={classes}
              onChange={handleFilters}
            />
            <CustomSelect
              id="industries"
              name="industries"
              placeholder="Industries"
              options={industriesOptions}
              value={industries}
              onChange={handleFilters}
            />
          </div>
        )}
        {!error ? null : <Message text={error.message} status="error" />}
        <ul className="community__list">
          {!result
            ? null
            : result.items.map((member: Member) => (
                <CommunityItem
                  member={member}
                  key={member._id}
                  hotMarker={hotMarker}
                  setHotMarker={setHotMarker}
                  setSelectedMemberId={setSelectedMemberId}
                  openUserModal={openUserModal}
                />
              ))}
        </ul>
        {result?.items.length && result?.pager?.total > 1 ? (
          <Pager total={result.pager.total} current={+page} />
        ) : null}
        {!loading ? null : (
          <div className="page-loader fade-in" style={{ margin: '16px 0' }}>
            <span className="sr-only">Loading</span>
            <div className="loader" />
          </div>
        )}
      </div>
      <div className="community__map">
        <CommunityMap
          members={result?.items}
          center={new google.maps.LatLng({ lat: 52.36, lng: 4.9 })}
          zoom={4}
          hotMarker={hotMarker}
          setHotMarker={setHotMarker}
          setSelectedMemberId={setSelectedMemberId}
          openUserModal={openUserModal}
        />
      </div>
      {userModal({
        children: (
          <CommunityDetails
            member={selectedMember}
            closeUserModal={closeUserModal}
            closeCallback={closeCallback}
          />
        ),
        side: 'right',
      })}
    </section>
  );
}

export default Community;
