import { MarkerClusterer, SuperClusterAlgorithm } from '@googlemaps/markerclusterer';
import { useEffect, useMemo, useRef, useState } from 'react';

import Marker from '../../../common/CustomMarker/CustomMarker';
import { Member } from '../../../core/models';
import CommunityMarker from '../CommunityMarker/CommunityMarker';
import './CommunityMap.css';
import styles from './styles.json';

interface Props {
  members: Member[];
  center: google.maps.LatLng;
  zoom: number;
  hotMarker: string | null;
  setHotMarker: React.Dispatch<React.SetStateAction<string | null>>;
  setSelectedMemberId: React.Dispatch<React.SetStateAction<string | null>>;
  openUserModal: (ref: HTMLButtonElement) => void;
}

function CommunityMap({
  members,
  center,
  zoom,
  hotMarker,
  setHotMarker,
  setSelectedMemberId,
  openUserModal,
}: Props) {
  const [map, setMap] = useState<google.maps.Map | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cluster, setCluster] = useState<MarkerClusterer>();
  const mapRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!mapRef.current || map) return;
    setMap(
      new window.google.maps.Map(mapRef.current, {
        center,
        zoom,
        maxZoom: 19,
        minZoom: 3,
        restriction: {
          latLngBounds: {
            east: 184,
            north: 85,
            south: -60,
            west: -175,
          },
        },
        styles,
        disableDefaultUI: true,
        zoomControl: true,
      }),
    );
  }, [mapRef, center, zoom]);

  const markers = useMemo(
    () =>
      !map || !members
        ? []
        : members
            .map((member) =>
              !member.current_location?.coords ||
              (member.current_location?.coords.lat === 0 &&
                member.current_location?.coords.lng === 0)
                ? null
                : new Marker({
                    map,
                    position: new google.maps.LatLng(member.current_location?.coords),
                    content: (
                      <CommunityMarker
                        member={member}
                        setHotMarker={setHotMarker}
                        setSelectedMemberId={setSelectedMemberId}
                        openUserModal={openUserModal}
                      />
                    ),
                    id: member._id,
                  }),
            )
            .filter((el) => el !== null),
    [map, members],
  );

  useEffect(() => {
    cluster?.clearMarkers();
  }, [markers]);

  useEffect(() => {
    markers.forEach((marker) => {
      if (!marker?.overlay.update) return;
      return marker.overlay.update(hotMarker === marker.id);
    });
  }, [hotMarker]);

  useEffect(() => {
    setCluster(
      new MarkerClusterer({
        map,
        markers: markers.map((marker) => marker?.init()),
        algorithm: new SuperClusterAlgorithm({ maxZoom: 8, radius: 60 }),
        renderer: {
          render: ({ count, position }) => {
            if (!map) return;
            return new Marker({
              map,
              position,
              content: <div className="map-cluster">{count}</div>,
            }).init();
          },
        },
      }),
    );
  }, [markers]);

  return <div className="community-map" ref={mapRef} id="map" />;
}

export default CommunityMap;
