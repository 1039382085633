import placeholder from '../../../assets/placeholder.png';
import { Member } from '../../../core/models';
import './CommunityCard.css';

function CommunityCard({ member }: { member: Member }) {
  return (
    <div
      style={
        {
          '--bg': member.bg_color,
        } as React.CSSProperties
      }
      className="comm-showcase__card"
    >
      <div className="comm-showcase__card-face comm-showcase__card-face--front">
        {!member.profile_pic ? (
          <img className="comm-showcase__img" src={placeholder} alt="THNK member" />
        ) : (
          <img
            className="comm-showcase__img"
            src={`${process.env.REACT_APP_API_URL}/uploads/${member.profile_pic.uri}`}
            alt={member.profile_pic.alt}
          />
        )}
      </div>
      <div className="comm-showcase__card-face comm-showcase__card-face--back">
        <div className="comm-showcase__name h3 absolute-center">
          <div>{member.firstname}</div>
          <div>{member.lastname}</div>
        </div>
      </div>
    </div>
  );
}

export default CommunityCard;
