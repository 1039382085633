import React from 'react';
import { useNavigate } from 'react-router-dom';

import CustomButton from '../../../common/CustomButton/CustomButton';
import { Icon } from '../../../common/Icon/Icon';
import './UserMenu.css';

function UserMenu() {
  const navigate = useNavigate();

  return (
    <div className="user-menu__container">
      <CustomButton
        type="button"
        className="user-menu__button h4 user-menu__underline"
        onClick={() => navigate('/profile')}
      >
        <Icon.Smile className="icon-large" />
        Profile page
      </CustomButton>
      <CustomButton
        type="button"
        className="user-menu__button h4"
        onClick={() => navigate('/account-settings')}
      >
        <Icon.Settings className="icon-large" />
        Account settings
      </CustomButton>
    </div>
  );
}

export default UserMenu;
