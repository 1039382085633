import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { A11y, FreeMode } from 'swiper';
import 'swiper/css';
import 'swiper/css/a11y';
import 'swiper/css/free-mode';
import { Swiper, SwiperSlide } from 'swiper/react';

import ComponentHeader from '../../../common/ComponentHeader/ComponentHeader';
import { Member } from '../../../core/models';
import CommunityCard from '../CommunityCard/CommunityCard';
import './CommunityShowcase.css';

function CommunityShowcase() {
  const [locked, setLocked] = useState<boolean>(false);

  const items = useMemo(
    () =>
      [].map((member: Member) => (
        <SwiperSlide className="comm-showcase__item" key={member._id}>
          <Link className="comm-showcase__link" to="/">
            <CommunityCard member={member} />
          </Link>
        </SwiperSlide>
      )),
    [],
  );

  return (
    <section className="comm-showcase section">
      <div className="wrap wrap--md">
        <ComponentHeader
          title="Check out our community"
          path="/community"
          linkText="See full community"
        />
      </div>
      <Swiper
        className={`comm-showcase__list${locked ? ' locked' : ''}`}
        slidesPerView="auto"
        freeMode={{ enabled: true, momentum: false }}
        watchOverflow
        onLock={() => setLocked(true)}
        onUnlock={() => setLocked(false)}
        modules={[FreeMode, A11y]}
        spaceBetween={24}
      >
        {items}
      </Swiper>
    </section>
  );
}

export default CommunityShowcase;
