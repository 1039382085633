import { useCallback } from 'react';

import CustomButton from '../../common/CustomButton/CustomButton';
import CustomForm from '../../common/CustomForm/CustomForm';
import CustomInput from '../../common/CustomInput/CustomInput';
import Message from '../../common/Message/Message';
import { isValidEmail } from '../../core/helpers';
import useFetch from '../../core/hooks/useFetch';
import { useFormInput } from '../../core/hooks/useFormInput';
import './Newsletter.css';

function Newsletter() {
  const { loading, error, result, doFetch } = useFetch('https://dog.ceo/api/breeds/image/random');
  const email = useFormInput('', (value) => isValidEmail(value));

  const submitForm = useCallback(() => {
    // doFetch({
    //   method: 'POST',
    //   body: email.value,
    // });
    doFetch();
  }, [email]);

  return (
    <section className="newsletter section">
      <div className="wrap wrap--xs">
        <h2 className="newsletter__title h2">Join the movement.</h2>
        <h3 className="newsletter__subtitle h3">
          Get leadership tips, innovation insights, and impact stories.
        </h3>
        <div className="newsletter__form-container">
          <CustomForm className="newsletter__form" onSubmit={submitForm} inputs={[email]}>
            <div className="newsletter__form-row">
              <CustomInput
                id="newsletter-email"
                type="text"
                name="email"
                placeholder="Enter your email address"
                label="Email address"
                input={email}
                emptyMessage="Please fill in your email"
                invalidMessage="Plese fill in a valid email address"
              />
              <CustomButton
                className="newsletter__cta btn btn--dark"
                type="submit"
                loading={loading}
              >
                Subscribe
              </CustomButton>
            </div>
          </CustomForm>
          <p className="newsletter__info body-small">
            Get your free copy of our ‘Shift your mindset to transform your leadership’ guidebook
          </p>
          {!result ? null : <Message text={result.message} status="success" />}
          {!error ? null : <Message text={error.message} status="error" />}
        </div>
        <div className="newsletter__spinner" />
      </div>
    </section>
  );
}

export default Newsletter;
