import { Tag } from '../../core/models';
import styles from './TagsList.module.css';

interface Props {
  tags: Tag[];
}

function TagsList({ tags }: Props) {
  return (
    <ul className={styles.list}>
      {tags.map((tag) => (
        <li className={`${styles.item} tag`} key={tag._id}>
          {tag.name}
        </li>
      ))}
    </ul>
  );
}

export default TagsList;
