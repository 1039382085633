import { Icon } from '../Icon/Icon';

interface Option {
  value: string;
  label: string;
}

interface Props {
  id: string;
  name: string;
  info?: string;
  label?: string;
  value?: string;
  error?: string;
  options: Option[];
  placeholder: string;
  emptyMessage?: string;
  disabled?: boolean;
  disablePlaceholder?: boolean;
  className?: string;
  onChange?(e: React.FormEvent<HTMLSelectElement>): void;
}

function CustomSelect({
  id,
  name,
  info,
  label,
  value,
  error,
  options,
  placeholder,
  emptyMessage,
  disablePlaceholder,
  className = '',
  disabled = false,
  onChange,
}: Props) {
  return (
    <div className={`input${!error ? '' : ' error'} ${className}`}>
      {!label ? null : !label ? null : (
        <label className="input__label" htmlFor={id}>
          {label}
        </label>
      )}
      <div className="input__wrapper">
        <select
          className="input__field input__field--select"
          name={name}
          id={id}
          required
          value={value}
          onChange={onChange}
          disabled={disabled}
          defaultValue={value !== undefined ? undefined : ''}
        >
          <option value="" disabled={disablePlaceholder} hidden={disablePlaceholder}>
            {placeholder}
          </option>
          {options.map((option) => (
            <option value={option.value} key={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <Icon.Chevron className="input__icon icon" />
        {!info ? null : <div className="input__info caption">{info}</div>}
      </div>
      {!error ? null : (
        <div className="input__error body-small">
          <span>{error === 'empty' ? emptyMessage : ''}</span>
        </div>
      )}
    </div>
  );
}

export default CustomSelect;
